const middleware = {}

middleware['age-check'] = require('../src/middleware/age-check.ts')
middleware['age-check'] = middleware['age-check'].default || middleware['age-check']

middleware['authenticated'] = require('../src/middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['guest'] = require('../src/middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['primeur-period'] = require('../src/middleware/primeur-period.ts')
middleware['primeur-period'] = middleware['primeur-period'].default || middleware['primeur-period']

middleware['security-header'] = require('../src/middleware/security-header.ts')
middleware['security-header'] = middleware['security-header'].default || middleware['security-header']

export default middleware
