import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { formatPhoneNumber } from '~/utils';
import { BaseAxiosAction } from '~/types/api';

export type State = {
  profile: {
    loginId?: string;
    firstName?: string;
    lastName?: string;
    firstNameKana?: string;
    lastNameKana?: string;
    gender?: string;
    birthday?: string;
    phoneNumber?: string;
    zipCode?: string;
    prefecture?: string;
    city?: string;
    streetAddress?: string;
  } | null;
};

export const state = (): State => ({
  profile: null,
});

export const mutations = {
  setProfile(state: State, payload: typeof state.profile) {
    if (payload?.phoneNumber) {
      // 想定しない電話番号を無視
      if (!/^[0-9-]+$/.test(payload.phoneNumber)) {
        payload.phoneNumber = undefined;
      }
      // 電話番号をパース
      else if (payload.phoneNumber.split('-').length <= 1) {
        payload.phoneNumber = formatPhoneNumber(payload.phoneNumber);
      }
    }

    state.profile = payload;
  },
};

export type Ctx = DefineActionContext<State, never, typeof mutations>;

export const actions = {
  async getAmazonSignature(this: Vue, context: Ctx, payload: BaseAxiosAction<{ data: string }>) {
    const { data, cancelToken } = payload;
    return await this.$ecAxios.$post('/api/v1/amazon_pay/signatures', { body: { data }, cancelToken });
  },
  async getAmazonAddresses(this: Vue, context: Ctx, payload: BaseAxiosAction<{ checkoutSessionId: string }>) {
    const { checkoutSessionId, cancelToken } = payload;
    return await this.$ecAxios.$get('/api/v1/amazon_pay/addresses/{checkout_session_id}', {
      path: {
        checkout_session_id: checkoutSessionId,
      },
      cancelToken,
    });
  },
  async amazonLogin(this: Vue, context: Ctx, checkoutSessionId: string) {
    return await this.$consolsAxios.$get('/api/v1/user/amazon/login', {
      query: {
        checkoutSessionId,
      },
    });
  },
  async amazonMerge(this: Vue, context: Ctx, checkoutSessionId: string) {
    return await this.$consolsAxios.$get('/api/v1/user/amazon/merge', {
      query: {
        checkoutSessionId,
      },
    });
  },
  /* ログイン中のアカウントがcheckoutSessionIdに紐づいてるか確認 */
  async amazonCheck(this: Vue, context: Ctx, checkoutSessionId: string) {
    /* status: 'OK' or 'NG'が返ってくる */
    const {
      user: { status },
    } = await this.$consolsAxios.$get('/api/v1/user/amazon/check', {
      query: {
        checkoutSessionId,
      },
    });

    return status === 'OK';
  },
};

export type Store = DefineStoreModule<'modules/amazonPay', State, never, typeof mutations, typeof actions>;
