import { computed } from '@nuxtjs/composition-api';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { useProductParams } from '~/composables/product/useProductParams';
import { useStore } from '~/composables/useStore';
import {
  PRODUCT_REVIEW_POINT_OPTIONS,
  PRODUCTION_YEAR_THRESHOLD,
  PRODUCT_SET_SIZE_OPTIONS,
  PRODUCT_COLOR_ID,
  SPARKLINGS,
  SWEETNESS_OPTIONS,
  PRODUCT_SIZE_LABELS,
  PRODUCT_PARENT_CATEGORY_ID,
  PRODUCT_SEARCH_PARAM,
  GROUP_HT,
  GROUP_HN,
  GROUP_TAG,
  WORD_TO_AVOID,
  PRODUCT_CELLAR_SIZE_LABELS,
  WINE_COLOR_IDS,
  PRODUCT_LIST_MAP,
  PRODUCT_ORDER_NUMBER_THRESHOLD,
  MEDIA_ORDERS,
} from '~/constants';
import { MasterItem } from '~/types/common/master';
import { ProductSearchSortOrder, ProductSearchSortTarget, ProductParentCategory } from '~/enums';

export type ProductSearchAPIParam = {
  q: string;
  sort?: string;
  log?: boolean;
  log_word?: string;
};

// レコメンドAPIのtracking_idを取得する
export const getTrackingId = (): Promise<string> => {
  return new Promise((resolve) => {
    if (!process.client) {
      resolve(Math.random().toString(36).replace('0.', ''));

      return;
    }

    window.TLAB.push(['Track::GetTrackingId', (id: string) => resolve(id)]);
  });
};

/**
 * パラメータをエスケープする
 * @param param 絞り込み条件
 * @returns {string}
 */
export const escapeParam = (param?: string | null) => {
  if (!param) return;
  // eslint-disable-next-line no-useless-escape
  return param.replace(/[+\-&|!(){}[\]^"~*?:/\\]/g, '\\$&');
};

export const useSearchAPIParam = () => {
  const { category, isPrimeur } = useProductParams();
  const store = useStore();

  const word = computed(() => store.state.product.filter.word);

  const idToLabels = (targetIdList: string[], masterItems: MasterItem[]) => {
    return masterItems.filter(({ id }) => targetIdList.includes(id)).map(({ label }) => label);
  };

  const arrayToSearchQuery = (items: string[]): string | undefined => {
    if (items.length === 0) {
      return;
    }

    if (items.length === 1) {
      return escapeParam(items[0]);
    }

    // eslint-disable-next-line no-useless-escape
    return `(${items.map((item) => escapeParam(item)).join(' OR ')})`;
  };

  /** ワインサイズ・セラー収納本数・プリムールサイズ本数のクエリを作成 */
  const sizeAmountI = computed((): string | undefined => {
    const { sizes: filterSizes, primeurSizes: filterPrimeurSizes, cellarSizes } = store.state.product.filter;

    if (category.value === ProductParentCategory.WINE_CELLAR) {
      const CELLAR_IDS = Object.values(PRODUCT_CELLAR_SIZE_LABELS).map(({ id }) => id);

      // ワインセラー本数
      const values = cellarSizes
        .filter((v) => CELLAR_IDS.includes(v))
        .map((size: string) => {
          const data = size.split('-');
          return `[${data[0]} TO ${data[1]}]`;
        });

      return values.length > 0 ? `(${values.join(' OR ')})` : undefined;
    } else if (isPrimeur.value) {
      // プリムール本数
      return arrayToSearchQuery(filterPrimeurSizes);
    } else {
      // ワインサイズ
      if (filterSizes.length === 0) return;
      const arr: string[] = [];
      filterSizes.forEach((size) => {
        if (size === PRODUCT_SIZE_LABELS.SMALL.id) {
          arr.push('[1 TO 699]');
        }
        if (size === PRODUCT_SIZE_LABELS.MEDIUM.id) {
          arr.push('[700 TO 1499]');
        }
        if (size === PRODUCT_SIZE_LABELS.LARGE.id) {
          arr.push('[1500 TO *]');
        }
      });

      if (arr.length === 1) {
        return arr[0];
      }

      return `(${arr.map((item) => item).join(' OR ')})`;
    }
  });

  /** ワインセット本数のクエリを作成 */
  const setQuantityI = computed((): string | undefined => {
    const { setSizes } = store.state.product.filter;

    const values = setSizes.map((size: string) => {
      if (size.includes(`${PRODUCT_SET_SIZE_OPTIONS.SMALL}`)) {
        return `[1 TO ${PRODUCT_SET_SIZE_OPTIONS.SMALL}]`;
      } else if (size.includes(`${PRODUCT_SET_SIZE_OPTIONS.MEDIUM[0]}`)) {
        return `[${PRODUCT_SET_SIZE_OPTIONS.MEDIUM[0]} TO ${PRODUCT_SET_SIZE_OPTIONS.MEDIUM[1]}]`;
      } else {
        return `[${PRODUCT_SET_SIZE_OPTIONS.LARGE} TO *]`;
      }
    });

    return values.length > 0 ? `(${values.join(' OR ')})` : undefined;
  });

  /** カテゴリのクエリを作成 */
  const productCategorySm = computed((): string | undefined => {
    const { categories } = store.state.product.filter;
    if (categories.length === 0) return;
    if (categories.length === 1) return categories[0];

    const parentCategory = categories.find((id) => Object.values(PRODUCT_PARENT_CATEGORY_ID).includes(id));
    const childCategoryQuery = arrayToSearchQuery(categories.filter((id) => id !== parentCategory));

    if (parentCategory) {
      return childCategoryQuery ? `(${parentCategory} AND ${childCategoryQuery})` : parentCategory;
    }
    return childCategoryQuery;
  });

  const singleWordQuery = (word: string) => {
    const escapedWord = escapeParam(word);

    if (!escapedWord) return '';

    const htQuery = GROUP_HT.map((param) => `${param}: "${escapedWord}"`).join('^5 OR ');
    const hnQuery = GROUP_HN.map((param) => `${param}: "${escapedWord}"`).join(' OR ');
    const tagQuery = GROUP_TAG.map((param) => `${param}: "${escapedWord}"`).join(' OR ');

    return `(${htQuery} OR ${hnQuery} OR ${tagQuery} OR stopper_s: (*${escapedWord}*) OR sale_info_s: (*${escapedWord}*))`;
  };

  /** フリーワード検索のクエリ */
  const wordQuery = computed(() => {
    if (!word.value) return '';

    const filteredWord = word.value.replace(WORD_TO_AVOID, '');
    // スペースで区切る（半角、全角、混合、連続スペース全部が対象）
    const splittedWords = filteredWord.split(/(\s|　)+/).filter((word) => word.trim() !== '');
    if (splittedWords.length === 0) return ''; // 送料無料単独入力の場合

    return `AND (${splittedWords.map(singleWordQuery).join(' AND ')})`;
  });

  const colorValue = computed(() => {
    const {
      filter: { colors },
    } = store.state.product;
    if (colors.includes(PRODUCT_COLOR_ID.SPARKLING)) {
      const state = colors.filter((v) => v !== PRODUCT_COLOR_ID.SPARKLING);
      return [...state, ...SPARKLINGS];
    }
    return colors;
  });

  const aocValue = computed(() => {
    const {
      filter: { aoc },
    } = store.state.product;

    return aoc;
  });

  const styleValue = computed(() => {
    const {
      filter: { styles },
    } = store.state.product;
    return styles;
  });

  const tags = computed(() => {
    const {
      filter: { tags },
    } = store.state.product;

    return tags;
  });

  const mediaReviews = computed(() => {
    const {
      filter: { mediaRatings, vintages },
    } = store.state.product;
    if (!mediaRatings || !mediaRatings.length) return;
    if (vintages && vintages.length > 0) {
      if (MEDIA_ORDERS.includes(mediaRatings[0])) {
        // ヴィンテージ指定があり、評価紙が未整形の場合、クエリを「ヴィンテージ_評価紙」の形式にする
        return `(${vintages
          .map((vintage: string) => mediaRatings.map((item: string) => `${vintage}_${item}`).join(' OR '))
          .join(' OR ')})`;
      }
      // ヴィンテージ指定があり、評価紙が「ヴィンテージ_評価紙」に整形済みの場合、クエリをそのまま繋げる
      return `(${mediaRatings.map((item: string) => escapeParam(item)).join(' OR ')})`;
    }
    // ヴィンテージ指定がない場合、評価紙を後方一致検索にする
    return `(${mediaRatings.map((item: string) => `*_${escapeParam(item)}`).join(' OR ')})`;
  });

  const searchQuery = computed(() => {
    return (param?: string) => {
      const { filter } = store.state.product;
      const today = dayjs().toISOString();

      const hasAllColor = isEqual(colorValue.value, WINE_COLOR_IDS);

      const queries = {
        mst: 'product',
        // 全てのワイン表示の時ctg=1をクエリに追加
        [PRODUCT_SEARCH_PARAM.CATEGORY]: productCategorySm.value || (hasAllColor ? '1' : undefined),
        [PRODUCT_SEARCH_PARAM.COLOR]: arrayToSearchQuery(colorValue.value),
        [PRODUCT_SEARCH_PARAM.AREA]: arrayToSearchQuery(filter.areas),
        [PRODUCT_SEARCH_PARAM.STYLE]: arrayToSearchQuery(styleValue.value),
        [PRODUCT_SEARCH_PARAM.VARIETY]: arrayToSearchQuery(filter.varieties),
        [PRODUCT_SEARCH_PARAM.MEDIA]: mediaReviews.value,
        [PRODUCT_SEARCH_PARAM.TASTE]:
          filter.tasteMin && filter.tasteMax ? `[${filter.tasteMin} TO ${filter.tasteMax}]` : undefined,
        [PRODUCT_SEARCH_PARAM.SWEETNESS]: arrayToSearchQuery(
          idToLabels(filter.sweetnesses, Object.values(SWEETNESS_OPTIONS))
        ),
        [PRODUCT_SEARCH_PARAM.SIZE]: sizeAmountI.value,
        [PRODUCT_SEARCH_PARAM.SET]: setQuantityI.value,
        [PRODUCT_SEARCH_PARAM.VINTAGE]: arrayToSearchQuery(filter.vintages),
        [PRODUCT_SEARCH_PARAM.CLASS]: arrayToSearchQuery(filter.classes),
        [PRODUCT_SEARCH_PARAM.PRODUCER]: filter.producer,
        [PRODUCT_SEARCH_PARAM.PRODUCT_VARIANT_PRICE]:
          filter.priceMin || filter.priceMax ? `[${filter.priceMin || '*'} TO ${filter.priceMax || '*'}]` : undefined,
        [PRODUCT_SEARCH_PARAM.SENSE]: escapeParam(filter.sense),
        [PRODUCT_SEARCH_PARAM.CODE]: escapeParam(filter.code)
          ? `(${escapeParam(filter.code)} OR ${escapeParam(filter.code)}_* OR *_${escapeParam(
              filter.code
            )}_* OR *_${escapeParam(filter.code)})`
          : undefined,
        [PRODUCT_SEARCH_PARAM.AOC]: aocValue.value,
        [PRODUCT_SEARCH_PARAM.TAG]: arrayToSearchQuery(tags.value),
        [PRODUCT_SEARCH_PARAM.REVIEW_POINT]:
          filter.reviewPoint === `${PRODUCT_REVIEW_POINT_OPTIONS.HEIGH}`
            ? `${PRODUCT_REVIEW_POINT_OPTIONS.HEIGH}`
            : filter.reviewPoint
            ? `[${filter.reviewPoint[0]} TO ${PRODUCT_REVIEW_POINT_OPTIONS.HEIGH}]`
            : undefined,
        [PRODUCT_SEARCH_PARAM.NEW]: filter.isNew || undefined,
        [PRODUCT_SEARCH_PARAM.FREE_SHIPPING]:
          filter.isFreeShipping || filter.word?.includes(WORD_TO_AVOID) ? '1' : undefined,
        [PRODUCT_SEARCH_PARAM.DISCOUNT]: filter.isDiscount ? '[1 TO *]' : undefined,
        [PRODUCT_SEARCH_PARAM.STOCK]: filter.hasStock || undefined,
      };

      const query = Object.entries(queries)
        .filter((keyval) => !!keyval[1])
        // モーダルの場合指定のパラメータを除く
        .filter(([key]) => key !== param)
        .map(([key, value]) => `${key}:${value}`)
        .join(' AND ');

      const PRODUCT_CODE_QUERY = 'AND product_code_s: *';

      // プリムール商品含む・含まない
      const primeurQuery = `${isPrimeur.value ? 'AND' : 'NOT'} product_type_s:プリムール`;
      // 販売期間外を除く
      const salesPeriodQuery = `NOT sales_start_d:[${today} TO *] NOT sales_end_d:[* TO ${today}]`;
      // 表示フラグ0を除く
      const REMOVE_NO_DISPLAY_QUERY = 'NOT display_flag_i:0';

      return `${query} ${PRODUCT_CODE_QUERY} ${primeurQuery} ${salesPeriodQuery} ${wordQuery.value} ${REMOVE_NO_DISPLAY_QUERY}`;
    };
  });

  // おすすめ順の頭に並び順4をつける
  const isOrderNumber4 = computed(() => {
    const { SNACK, GIFT_SET, WINE_SET } = ProductParentCategory;
    const { categories } = store.state.product.filter;

    if (categories.length === 0) return false;

    const parentCategory = categories.find((id) => Object.values(PRODUCT_PARENT_CATEGORY_ID).includes(id));

    const category = parentCategory as ProductParentCategory | undefined;

    return !!category && [SNACK, GIFT_SET, WINE_SET].includes(category);
  });

  const isSetQuery = computed(() => {
    const { categories } = store.state.product.filter;
    return categories.includes(ProductParentCategory.WINE_SET);
  });

  const sort = computed((): string => {
    const { sortOrder, sortTarget, hasStock, tags } = store.state.product.filter;
    const { product_order_number_3, product_order_number_4 } = PRODUCT_LIST_MAP;

    const orderNumber4 = isOrderNumber4.value
      ? `if(exists(${product_order_number_4}), ${product_order_number_4}, ${PRODUCT_ORDER_NUMBER_THRESHOLD}) ${ProductSearchSortOrder.ASC}, `
      : '';

    const orderNumber3 = tags.includes('セール') ? `${product_order_number_3} ${ProductSearchSortOrder.DESC}, ` : '';

    const hasStockSort = !hasStock ? `${PRODUCT_SEARCH_PARAM.STOCK} ${ProductSearchSortOrder.DESC}, ` : '';

    // デフォルト：おすすめ順（おすすめフラグ昇順 + 生産者重み降順 + 価格昇順 + スコア降順）
    // ワインセット、ギフトセット、食品の絞り込みは先頭に並び順4をつける
    // セールタグの絞り込みは先頭に並び順3をつける
    // ワインセット＋セールタグの場合、並び順4>並び順3の優先順いで
    const DEFAULT_SORT = `${orderNumber4}${orderNumber3}recommend_flag_i ${ProductSearchSortOrder.ASC},producer_weight_i ${ProductSearchSortOrder.DESC},lowest_price_general_i ${ProductSearchSortOrder.ASC},score ${ProductSearchSortOrder.DESC}`;

    if (sortTarget === ProductSearchSortTarget.PRICE && sortOrder === ProductSearchSortOrder.ASC) {
      return `${hasStockSort}lowest_price_general_i ${sortOrder}, ${DEFAULT_SORT}`;
    }

    // プリムールのデフォルト：価格が安い順
    if (isPrimeur.value && !sortOrder) {
      return `${hasStockSort}lowest_price_general_i ${ProductSearchSortOrder.ASC}, ${DEFAULT_SORT}`;
    }

    if (sortTarget === ProductSearchSortTarget.PRICE && sortOrder === ProductSearchSortOrder.DESC) {
      return `${hasStockSort} highest_price_general_i ${sortOrder}, ${DEFAULT_SORT}`;
    }

    if (sortTarget === ProductSearchSortTarget.VINTAGE && sortOrder === ProductSearchSortOrder.ASC) {
      return `${hasStockSort} if(exists(earliest_produced_year_i),earliest_produced_year_i,${PRODUCTION_YEAR_THRESHOLD}) ${sortOrder}, ${DEFAULT_SORT}`;
    }

    if (sortTarget === ProductSearchSortTarget.VINTAGE && sortOrder === ProductSearchSortOrder.DESC) {
      return `${hasStockSort} latest_produced_year_i ${sortOrder}, ${DEFAULT_SORT}`;
    }

    if (sortTarget === ProductSearchSortTarget.REVIEW && sortOrder === ProductSearchSortOrder.DESC) {
      return `${hasStockSort} sort_r_rating_r ${sortOrder}, ${DEFAULT_SORT}`;
    }

    if (sortTarget === ProductSearchSortTarget.MEDIA && sortOrder === ProductSearchSortOrder.DESC) {
      return `${hasStockSort} vintage_media_best_score_i ${sortOrder}, vintage_media_display_order_i ${ProductSearchSortOrder.ASC}, ${DEFAULT_SORT}`;
    }

    if (sortTarget === ProductSearchSortTarget.SIZE) {
      return `${hasStockSort} sort_size_amount_i ${sortOrder}, ${DEFAULT_SORT}`;
    }

    if (sortTarget === ProductSearchSortTarget.ARRIVAL && sortOrder === ProductSearchSortOrder.DESC) {
      return `${hasStockSort} sort_newer_d ${sortOrder}, ${DEFAULT_SORT}`;
    }
    /**
     * 「おすすめ順」ソート時、下記条件を満たす場合は「一覧優先表示フラグのある商品」を優先して表示する
     * ・プリムール商品一覧ではない
     * ・ワインセット商品一覧ではない
     */
    if (!isPrimeur.value && !isSetQuery.value) {
      return `priority_display_flag_i ${ProductSearchSortOrder.DESC},${hasStockSort} ${DEFAULT_SORT}`;
    }
    return `${hasStockSort} ${DEFAULT_SORT}`;
  });
  const param = computed(
    (): ProductSearchAPIParam => ({
      q: searchQuery.value(),
      sort: sort.value,
      log: !!word.value || undefined,
      log_word: word.value || undefined,
    })
  );

  return { param, searchQuery, aocValue, colorValue };
};
