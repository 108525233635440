type FacebookEventType = 'ViewContent' | 'AddToCart' | 'Purchase';

/**
 * facebook：タグの設置
 * @param eventName イベント名
 * @param price 合計税抜価格
 * @param codeList 商品コードの配列
 * @returns
 */
export const facebookTrack = (eventName: FacebookEventType, price: number, codeList: string[]) => {
  if (typeof window.fbq !== 'function') {
    return;
  }
  window.fbq('track', eventName, {
    value: price,
    currency: 'JPY',
    content_ids: codeList,
    content_type: 'product',
  });
};
