import { DefineStoreModule, DefineActionContext } from '@lollipop-onl/vuex-typesafe-helper';
import Vue from 'vue';
import { PRODUCT_TAG_ID } from '~/constants';
import { BaseAxiosAction, ECActionPayload } from '~/types/api';
import { MasterItem } from '~/types/common/master';
import { SearchTag2 } from '~/types/common/tag';
import { BrandColor, ProducingAreaCountry } from '~/types/modules/foodDiagnosis';
import { ProductParentCategory, ProductSearchSortOrder } from '~/enums';

/** State */
export interface State {
  areas: MasterItem[];
  snackCategories: MasterItem[];
  wineGoodsCategories: MasterItem[];
  area: MasterItem | null;
  searchTarget: MasterItem | null;
  vintageTarget: MasterItem | null;
  tagInformation: SearchTag2 | null;
}

export const state = (): State => ({
  areas: [],
  snackCategories: [],
  wineGoodsCategories: [],
  area: null,
  searchTarget: null,
  vintageTarget: null,
  tagInformation: null,
});

/** Getters */
export const getters = {};

/** Mutations */
export const mutations = {
  setFieldValue<Field extends keyof State>(state: State, payload: { field: Field; value: State[Field] }): void {
    Vue.typedSet(state, payload.field, payload.value);
  },
  resetTarget(state: State) {
    state.searchTarget = null;
    state.vintageTarget = null;
    state.tagInformation = null;
    state.area = null;
  },
};

/** Actions */
export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;
export const actions = {
  async fetchColor(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ id: string }>) {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:color AND color_id_i:${payload.id}`,
      },
    });
    const docs = response?.docs || [];
    const colors = docs.map(
      (item: any): MasterItem => ({
        id: `${item.color_id_i}`,
        label: item.color_name_s,
        main_image: item.main_image_url_s,
        description: item.description_t,
        meta_description: item.meta_description_t,
        banner_url_1: item.banner_url1_s,
        banner_url_2: item.banner_url2_s,
        banner_url_3: item.banner_url3_s,
        banner_image_1: item.banner_image1_s,
        banner_image_2: item.banner_image2_s,
        banner_image_3: item.banner_image3_s,
        button_name: item.description_button_name_s,
        button_url: item.description_button_url_s,
      })
    );

    commit('setFieldValue', { field: 'searchTarget', value: colors[0] });
  },
  async fetchVariety(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ id: string }>) {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:brand AND brand_id_i:${payload.id}`,
      },
    });

    const docs = response?.docs || [];
    const varieties = docs.map(
      (item: any): MasterItem => ({
        id: `${item.brand_id_i}`,
        label: item.brand_name_s,
        label_en: item.brand_name_en_s,
        main_image: item.main_image_url_s,
        description: item.description_t,
        meta_description: item.meta_description_t,
        banner_url_1: item.banner_url1_s,
        banner_url_2: item.banner_url2_s,
        banner_url_3: item.banner_url3_s,
        banner_image_1: item.banner_image1_s,
        banner_image_2: item.banner_image2_s,
        banner_image_3: item.banner_image3_s,
        button_name: item.description_button_name_s,
        button_url: item.description_button_url_s,
      })
    );

    commit('setFieldValue', { field: 'searchTarget', value: varieties[0] });
  },

  async fetchParentVarieties(this: Vue, Context: Ctx, payload: BaseAxiosAction<{ ids: string[] }>) {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:brand AND brand_id_i:(${payload.ids.join(' OR ')})`,
        fl: ['brand_id_i', 'parent_brand_id_i'],
      },
    });
    // parent_brand_id_iがなければbrand_id_iがすでに親であること
    return (
      response?.docs.map((item: any) => item.parent_brand_id_i || item.brand_id_i).sort((a: any, b: any) => a - b) || []
    );
  },

  async fetchVarietiesColor(
    this: Vue,
    Context: Ctx,
    payload: BaseAxiosAction<{ grapes: string[] }>
  ): Promise<BrandColor[]> {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:brand AND brand_name_s: (${payload.grapes.join(' OR ')})`,
        fl: ['brand_name_s', 'brand_color_s'],
      },
    });
    return (
      response?.docs?.map((item: any) => ({
        color: item.brand_color_s,
        name: item.brand_name_s,
      })) || []
    );
  },

  async fetchParentCategoryId(
    this: Vue,
    Context: Ctx,
    payload: BaseAxiosAction<{ childId: string | number }>
  ): Promise<number | null> {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:category AND category_id_i:${payload.childId}`,
        fl: ['parent_category_id_i'],
      },
    });
    return response?.docs[0]?.parent_category_id_i || null;
  },

  async fetchCategory(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ id: string }>) {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:category AND category_id_i:${payload.id}`,
      },
    });
    const docs = response?.docs || [];
    const categories = docs.map(
      (item: any): MasterItem => ({
        id: `${item.category_id_i}`,
        label: item.category_name_s,
        main_image: item.main_image_url_s,
        description: item.description_t,
        meta_description: item.meta_description_t,
        banner_url_1: item.banner_url1_s,
        banner_url_2: item.banner_url2_s,
        banner_url_3: item.banner_url3_s,
        banner_image_1: item.banner_image1_s,
        banner_image_2: item.banner_image2_s,
        banner_image_3: item.banner_image3_s,
        button_name: item.description_button_name_s,
        button_url: item.description_button_url_s,
      })
    );

    commit('setFieldValue', { field: 'searchTarget', value: categories[0] });
  },

  async fetchAocDetail(this: Vue, { commit }: Ctx, payload: ECActionPayload<'/api/v1/native_areas/{id}', 'get'>) {
    const { id, name_kana, name } = await this.$ecAxios.$get('/api/v1/native_areas/{id}', payload);
    const value = {
      id: id.toString(),
      label: name_kana,
      label_en: name,
    };

    commit('setFieldValue', {
      field: 'searchTarget',
      value,
    });
  },

  async fetchTagDetail(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ tag: string }>) {
    const { tag } = payload;
    try {
      const { response } = await this.$searchAxios.$get('/select', {
        query: {
          wt: 'json',
          q: `mst:tag2 AND tag2_name_s:${tag}`,
        },
      });
      const docs = response?.docs || [];
      if (docs.length > 0) {
        // 検索時FV画像と特集を取得するため、tagInformationに関連情報を格納する
        commit('setFieldValue', {
          field: 'tagInformation',
          value: docs[0],
        });
        commit('setFieldValue', {
          field: 'searchTarget',
          value: {
            id: PRODUCT_TAG_ID,
            label: tag,
            description: docs[0].tag2_description_s,
            meta_description: docs[0].tag2_description_s,
          },
        });
      }
    } catch {
      // 何もしない
    }
  },

  async fetchArea(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ id: string; isSubTarget?: boolean }>) {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:producing_area AND producing_area_id_i:${payload.id}`,
      },
    });
    const docs = response?.docs || [];
    const areas = docs.map(
      (item: any): MasterItem => ({
        id: `${item.area_code_s}`,
        label: item.producing_area_name_s,
        main_image: item.main_image_url_s,
        description: item.description_t,
        meta_description: item.meta_description_t,
        producing_area_name_en_s: item.producing_area_name_en_s,
        banner_url_1: item.banner_url1_s,
        banner_url_2: item.banner_url2_s,
        banner_url_3: item.banner_url3_s,
        banner_image_1: item.banner_image1_s,
        banner_image_2: item.banner_image2_s,
        banner_image_3: item.banner_image3_s,
        button_name: item.description_button_name_s,
        button_url: item.description_button_url_s,
      })
    );

    payload.isSubTarget
      ? commit('setFieldValue', { field: 'area', value: areas[0] }) // ヴィンテージチャートから遷移した時に使用
      : commit('setFieldValue', { field: 'searchTarget', value: areas[0] });
  },

  async fetchVintage(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ id: string; isSingleColor: boolean }>) {
    const { id, isSingleColor } = payload;
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:vintage AND iid:vintage_${id}`,
      },
    });
    const docs = response?.docs || [];

    const colorLabel = (id: number) => (id === 1 ? '赤ワイン' : '白ワイン');

    const vintages = docs.map(
      (item: any): MasterItem => ({
        id: `${item.area_code_s}`,
        label: isSingleColor ? `${item.vintage_code_s}年 ${colorLabel(item.color_id_i)}` : `${item.vintage_code_s}年`,
        description: item.description_t || '',
      })
    );

    commit('setFieldValue', { field: 'searchTarget', value: vintages[0] });
  },

  async fetchAreaCountry(
    this: Vue,
    Context: Ctx,
    payload: BaseAxiosAction<{ producingAreaId: number }>
  ): Promise<ProducingAreaCountry[]> {
    const { response } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:producing_area AND producing_area_id_i: ${payload.producingAreaId}`,
        fl: ['country_id_i', 'producing_area_name_s'],
      },
    });
    return (
      response?.docs?.map((item: any) => ({
        countryId: item.country_id_i,
        areaName: item.producing_area_name_s,
      })) || []
    );
  },

  async fetchSnackCategories(this: Vue, { commit }: Ctx, payload: BaseAxiosAction) {
    const { response } = await this.$searchAxios.$get('/select', {
      ...payload,
      query: {
        wt: 'json',
        rows: this.$C.MASTER_LIMIT_ROWS,
        q: `mst:category AND parent_category_id_i: ${ProductParentCategory.SNACK}`,
        fl: ['category_id_i', 'category_name_s'],
        sort: `display_order_i ${ProductSearchSortOrder.ASC}`,
      },
    });
    const docs = response?.docs || [];
    const categories = docs.map(
      (item: any): MasterItem => ({
        id: `${item.category_id_i}`,
        label: item.category_name_s,
      })
    );

    commit('setFieldValue', { field: 'snackCategories', value: categories });
  },

  async fetchWineGoodsCategories(this: Vue, { commit }: Ctx, payload: BaseAxiosAction) {
    const { response } = await this.$searchAxios.$get('/select', {
      ...payload,
      query: {
        wt: 'json',
        rows: this.$C.MASTER_LIMIT_ROWS,
        q: `mst:category AND parent_category_id_i: ${ProductParentCategory.WINE_GOODS}`,
        fl: ['category_id_i', 'category_name_s'],
        sort: `display_order_i ${ProductSearchSortOrder.ASC}`,
      },
    });
    const docs = response?.docs || [];
    const categories = docs.map(
      (item: any): MasterItem => ({
        id: `${item.category_id_i}`,
        label: item.category_name_s,
      })
    );

    commit('setFieldValue', { field: 'wineGoodsCategories', value: categories });
  },
};

/** Store Module Type */
export type Store = DefineStoreModule<'product/master', State, typeof getters, typeof mutations, typeof actions>;
