import { DefineActionContext, DefineStoreModule } from '@lollipop-onl/vuex-typesafe-helper';
import dayjs from 'dayjs';
import Vue from 'vue';
import {
  DELIVERY_DATE_DISTRIBUTION,
  DELIVERY_DATE_UNDEFINED,
  DELIVERY_DATE_UNDEFINED_OPTION,
  DELIVERY_TIME_UNDEFINED,
  LOCAL_STORAGE_KEY,
} from '~/constants';
import {
  createOrderReq,
  createSimulationReq,
  createDistributeSimulationReq,
  createDistributionOrderReq,
} from '~/utils';
import { BaseAxiosAction, ECActionPayload, ECSchema } from '~/types/api';
import { CartSchema } from '~/types/modules/cart';
import {
  OrderSimulations,
  OrderCreateResponse,
  UserOptionType,
  UserOptionReceipt,
  SpecificationType,
  SenderAddress,
  UserOptionPayment,
  GiftWrappingType,
  GiftOrder,
  GiftOption,
  UserOptionShipment,
  UserOptionPaperBag,
  OrderItem,
  AmazonAddress,
  OrderRegister,
  DistributeSimulations,
  SelectableLeaflet,
  DistributionCourseInfo,
} from '~/types/modules/order';
import { CartType, PaymentSplitNum, ReceiptType, OrderErrorCodes, DisplayLang } from '~/enums';

export type State = {
  simulationRes: OrderSimulations | null;
  distributeSimulationRes: DistributeSimulations | null;
  subscriptionId: number | null;
  orderRes: OrderCreateResponse | null;
  veritransOrderInfo: ECSchema<'veritrans.OrderInfo'> | null;
  orderedCartId?: number;
  // <!> GTM で Yahoo! コンバージョンへの送信に使っているのでプロパティの指定が変わるときは注意
  orderConversionValue: number | null;
  userOption: UserOptionType;
  isUsedCoupon: boolean;
  giftOrder: GiftOrder;
  giftOrderCellarInName: string;
  giftWrapping: GiftWrappingType[];
  giftOptionRes: GiftOption[];
  register: OrderRegister | null;
  registerCartId: number | null;
  /** DeliveryCardのローディングフラグ */
  loadingDelivery: boolean;
  shouldDisplayCellarAlert: boolean;
  /** Veritrans 決済時の注文シミュレーション・カート情報保持 */
  veritransOrderedTemporals: {
    cartId: number;
    simulationRes: OrderSimulations | null;
    carts: CartSchema[];
  } | null;
  previousPaymentMethodId: number | null;
  distributionCourseInfo: DistributionCourseInfo | null;
};

const getDefaultUserOption = (): UserOptionType => ({
  lang_id: DisplayLang.JA,
  affiliate: null,
  cartName: null,
  distributionCourseId: null,
  senderAddress: {
    lastName: '',
    firstName: '',
    zipCode: '',
    prefectureId: 1,
    city: '',
    address: '',
    building: '',
    phoneNumber: '',
    companyName: '',
    jobTitle: '',
  },
  payment: {
    paymentMethodId: null,
    paymentSplitNum: PaymentSplitNum.LUMP_SUM,
  },
  receipt: {
    proviso: '',
    sendTo: '',
    type: ReceiptType.WEB,
  },
  shipments: [],
  couponCode: '',
  usedPoint: null,
  note: '',
  primeurAgreement: false,
  distributionAgreement: false,
  isAmazonGuestOrder: false,
  hasCheckedPrivacyPolicy: false,
  hasCheckedLigerBelairPolicy: false,
});

export const state = (): State => ({
  simulationRes: null,
  subscriptionId: null,
  orderRes: null,
  veritransOrderInfo: null,
  orderConversionValue: null,
  distributeSimulationRes: null,
  userOption: getDefaultUserOption(),
  isUsedCoupon: true,
  giftOrder: [],
  giftOrderCellarInName: '',
  giftWrapping: [],
  giftOptionRes: [],
  register: null,
  registerCartId: null,
  loadingDelivery: false,
  shouldDisplayCellarAlert: false,
  veritransOrderedTemporals: null,
  previousPaymentMethodId: null,
  distributionCourseInfo: null,
});

export const getters = {
  /* 選択されてる配送日・時間帯をフロント表示用に変換 */
  deliveryDateAndTime: (state: State) => (index: number) => {
    const deliveryDate = state.userOption.shipments[index]?.deliveryDate;
    const deliveryTimeId = state.userOption.shipments[index]?.deliveryTimeId;
    const isDistributionOrder = state.userOption.cartName === CartType.DISTRIBUTION;

    if (!isDistributionOrder) {
      return {
        date:
          deliveryDate === DELIVERY_DATE_UNDEFINED_OPTION.VALUE
            ? DELIVERY_DATE_UNDEFINED_OPTION.TEXT
            : deliveryDate
            ? dayjs(deliveryDate).tz('Asia/Tokyo').format('MMMMDo (dd)')
            : DELIVERY_DATE_UNDEFINED,
        time:
          state.simulationRes?.shipments[index].delivery_dates
            .find((dateAndTime) => dateAndTime.date === deliveryDate)
            ?.times.find((time) => time.id === deliveryTimeId)?.name || DELIVERY_TIME_UNDEFINED.TEXT,
      };
    }

    return {
      date: DELIVERY_DATE_DISTRIBUTION,
      time:
        state.distributeSimulationRes?.shipments[0].default_delivery_times.find(
          (time) => time.id === state.userOption.shipments[0].deliveryTimeId
        )?.name || DELIVERY_TIME_UNDEFINED.TEXT,
    };
  },
  /* 選択されてる配送方法をフロント表示用に変換 */
  shippingMethodName: (state: State) => (index: number) => {
    return state.simulationRes?.shipments[index]?.shipping_methods.find(
      (method) => method.id === state.userOption.shipments[index]?.shippingMethodId
    )?.name;
  },
  /* ギフト注文かどうかのフラグ */
  isGiftOrder: (state: State) => {
    return state.userOption.shipments.length >= 2;
  },
  /* queryのshipmentIndexをフロントで使える形に変換 */
  shipmentIndex:
    (state: State) =>
    (index: any): number => {
      return state.userOption.shipments[index] ? index : 0;
    },
  /** 注文にレンタルセラーINが含まれているかのフラグ */
  isSomeRentalCellar: (state: State) => {
    return state.userOption.shipments.some((shipment) => shipment.toRentalCellar);
  },
  /** シミュレーションのレスポンスに該当エラーが含まれているかどうか */
  hasError: (state: State) => (code: OrderErrorCodes) => {
    return state.simulationRes?.error_codes.includes(code);
  },
};

export const mutations = {
  setCartName(state: State, cartName: CartType) {
    state.userOption.cartName = cartName;
  },
  setSimulationRes(state: State, payload: OrderSimulations | null) {
    state.simulationRes = payload;
  },
  setSubscriptionId(state: State, payload: number | null) {
    state.subscriptionId = payload;
  },
  setOrderRes(state: State, payload: OrderCreateResponse) {
    state.orderRes = payload;
  },
  setOrderConversionValue(state: State, value: number | null) {
    state.orderConversionValue = value;
  },
  setGiftOptionRes(state: State, payload: any) {
    state.giftOptionRes = payload;
  },
  setOrderedCartId(state: State, payload: number) {
    state.orderedCartId = payload;
  },
  setSenderAddress(state: State, payload: SenderAddress) {
    state.userOption.senderAddress = payload;
  },
  setReceiptType(state: State, payload: ReceiptType) {
    state.userOption.receipt.type = payload;
  },
  setReceipt(state: State, payload: UserOptionReceipt) {
    state.userOption.receipt = payload;
  },
  setShippingMethodId(state: State, { id, index }: { id: number; index: number }) {
    Vue.typedSet(state.userOption.shipments, index, { ...state.userOption.shipments[index], shippingMethodId: id });
  },
  setDelivery(
    state: State,
    { deliveryName, primaryAddress, index }: { deliveryName: string; primaryAddress: boolean; index: number }
  ) {
    Vue.typedSet(state.userOption.shipments, index, {
      ...state.userOption.shipments[index],
      deliveryName,
      primaryAddress,
    });
  },
  setToRentalCellar(state: State, { toRentalCellar, index }: { toRentalCellar: boolean; index: number }) {
    Vue.typedSet(state.userOption.shipments, index, { ...state.userOption.shipments[index], toRentalCellar });
  },
  setDeliveryDate(state: State, { date, index }: { date?: string; index: number }) {
    Vue.typedSet(state.userOption.shipments, index, { ...state.userOption.shipments[index], deliveryDate: date });
  },
  setDeliveryTime(state: State, { timeId, index }: { timeId?: number; index: number }) {
    Vue.typedSet(state.userOption.shipments, index, { ...state.userOption.shipments[index], deliveryTimeId: timeId });
  },
  setHasDeliveryBox(state: State, { flag, index }: { flag?: boolean; index: number }) {
    state.userOption.shipments[index].hasDeliveryBox = flag;
  },
  setSpecification(
    state: State,
    { specificationType, index }: { specificationType: SpecificationType; index: number }
  ) {
    Vue.typedSet(state.userOption.shipments, index, {
      ...state.userOption.shipments[index],
      specificationType,
    });
  },
  setPayment(state: State, payload: UserOptionPayment) {
    state.previousPaymentMethodId = state.userOption.payment.paymentMethodId;
    Vue.typedSet(state.userOption, 'payment', payload);
  },
  setPaymentToken(state: State, payload?: string) {
    state.userOption.payment.payment_token = payload;
  },
  setCouponCode(state: State, couponCode: string) {
    state.userOption.couponCode = couponCode;
  },
  setIsUsedCoupon(state: State, flag: boolean) {
    state.isUsedCoupon = flag;
  },
  setUsedPoint(state: State, usedPoint: number) {
    state.userOption.usedPoint = usedPoint;
  },
  setPaymentAmount(state: State, paymentAmount: number) {
    state.userOption.payment.paymentAmount = paymentAmount;
  },
  setShipmentNote(state: State, note: string) {
    state.userOption.note = note;
  },
  setCellarInfo(state: State, wineCellarInfo?: string) {
    state.userOption.shipments[0].wineCellarInfo = wineCellarInfo;
  },
  setUserOption(state: State, payload: UserOptionType) {
    state.userOption = payload;
  },
  setShipment(state: State, { payload, index }: { payload: UserOptionShipment; index: number }) {
    Vue.typedSet(state.userOption.shipments, index, { ...state.userOption.shipments[index], ...payload });
  },
  setGiftOrder(state: State, payload: GiftOrder) {
    state.giftOrder = payload;
  },
  setGiftOrderDeliveries(state: State, payload: { deliveryName: string; primaryAddress: boolean }[]) {
    state.giftOrder = payload;
  },
  /** レンタルセラーINを判別するためのユニークなstringをセット */
  setGiftOrderCellarInName(state: State, payload: string) {
    state.giftOrderCellarInName = payload;
  },
  setHasPaperBags(state: State, { hasPaperBags, index }: { hasPaperBags: boolean; index: number }) {
    Vue.typedSet(state.userOption.shipments, index, {
      ...state.userOption.shipments[index],
      hasPaperBags,
    });
  },
  setPaperBags(state: State, { payload, index }: { payload: UserOptionPaperBag; index: number }) {
    Vue.typedSet(state.userOption.shipments[index], 'paperBags', {
      ...state.userOption.shipments[index].paperBags,
      ...payload,
    });
  },
  setGiftWrapping(state: State, { payload, index }: { payload: GiftWrappingType[]; index: number }) {
    Vue.typedSet(state.userOption.shipments[index], 'giftWrappings', payload);
  },
  addGiftItem(state: State, { item, index }: { item: OrderItem; index: number }) {
    Vue.typedSet(state.userOption.shipments[index], 'giftItems', [
      ...(state.userOption.shipments[index].giftItems || []),
      item,
    ]);
  },
  setAmazonAddress(state: State, { address, index }: { address?: AmazonAddress; index: number }) {
    Vue.typedSet(state.userOption.shipments[index], 'address', address);
  },
  setIsAmazonGuestOrder(state: State, payload: boolean) {
    state.userOption.isAmazonGuestOrder = payload;
  },
  setRegister(state: State, payload: OrderRegister) {
    state.register = payload;
  },
  setRegisterCartId(state: State, cartId: number) {
    state.registerCartId = cartId;
  },
  clearRegister(state: State) {
    state.register = null;
    state.registerCartId = null;
  },
  setPrimeurAgreement(state: State, payload: boolean) {
    state.userOption.primeurAgreement = payload;
  },
  setDistributionCourseId(state: State, payload: number) {
    state.userOption.distributionCourseId = payload;
  },
  setDistributionCourseInfo(state: State, payload: State['distributionCourseInfo']) {
    state.distributionCourseInfo = payload;
  },
  setDistributeSimulationRes(state: State, payload: DistributeSimulations | null) {
    Vue.typedSet(state, 'distributeSimulationRes', payload);
  },
  setDistributionAgreement(state: State, payload: boolean) {
    state.userOption.distributionAgreement = payload;
  },
  /* アフィリエイトcookie情報のセット */
  setAffiliate(state: State, payload: { tranAt: string; tranId: string }) {
    state.userOption.affiliate = payload;
  },
  setLangId(state: State, payload: DisplayLang) {
    state.userOption.lang_id = payload;
  },
  setLoadingDelivery(state: State, payload: boolean) {
    state.loadingDelivery = payload;
  },
  setShouldDisplayCellarAlert(state: State, payload: boolean) {
    state.shouldDisplayCellarAlert = payload;
  },
  setVeritransOrderInfo(state: State, payload: ECSchema<'veritrans.OrderInfo'>) {
    Vue.typedSet(state, 'veritransOrderInfo', payload);
  },
  setHasCheckedPrivacyPolicy(state: State, payload: boolean) {
    state.userOption.hasCheckedPrivacyPolicy = payload;
  },
  setHasCheckedLigerBelairPolicy(state: State, payload: boolean) {
    state.userOption.hasCheckedLigerBelairPolicy = payload;
  },
  setSelectableLeaflet(state: State, { payload, index }: { payload: SelectableLeaflet[]; index: number }) {
    Vue.typedSet(state.userOption.shipments[index], 'leaflets', payload);
  },
  setVeritransOrderedTemporals(state: State, payload: State['veritransOrderedTemporals']) {
    state.veritransOrderedTemporals = payload;
  },
  setPreviousPaymentMethod(state: State, payload: number) {
    state.previousPaymentMethodId = payload;
  },
};

export type Ctx = DefineActionContext<State, typeof getters, typeof mutations>;

export const actions = {
  async postSimulation(
    this: Vue,
    { state, commit, rootState }: Ctx,
    { cartId, cancelToken }: BaseAxiosAction<{ cartId: number }>
  ) {
    const result = rootState.auth.authenticated
      ? await this.$ecAxios.$post('/api/v1/order_simulations', {
          body: createSimulationReq(cartId, state.userOption, state.giftOptionRes),
          cancelToken,
        })
      : await this.$ecAxios.$post('/api/v1/guest_order_simulations', {
          // @ts-expect-error
          query: {
            guest_token: rootState.modules.cart.guestToken as string,
          },
          body: createSimulationReq(cartId, state.userOption, state.giftOptionRes),
          cancelToken,
        });

    if (result.error_codes?.length > 0) {
      this.$logger.send({
        method: 'post',
        url: 'order_simulations errors',
        status: '200',
        errorCode: result.error_codes.join(','),
      });
    }

    commit('setPaymentAmount', result.price_set.total_amount);
    commit('setSimulationRes', result);
  },
  async postOrder(this: Vue, { state, commit }: Ctx, { cartId, cancelToken }: BaseAxiosAction<{ cartId: number }>) {
    commit('setOrderConversionValue', null);

    const body = createOrderReq(cartId, state.userOption, state.giftOptionRes, state.simulationRes);

    const result = await this.$ecAxios.$post('/api/v1/orders', {
      body,
      cancelToken,
    });

    commit('setOrderRes', result);
    commit('setOrderedCartId', cartId);

    if (body.payment?.payment_amount != null) {
      commit('setOrderConversionValue', body.payment.payment_amount);
    }

    return result;
  },
  async postDistributionSimulation(this: Vue, { commit, state }: Ctx, { cancelToken }: BaseAxiosAction) {
    const body = createDistributeSimulationReq(Number(state.userOption.distributionCourseId), state.userOption);

    if (!body) return;

    const result = await this.$ecAxios.$post('/api/v1/distribution_course_subscription_simulations', {
      body,
      cancelToken,
    });
    commit('setDistributeSimulationRes', result);
  },
  async postDistributionOrder(this: Vue, { commit, state }: Ctx, { cancelToken }: BaseAxiosAction) {
    const body = createDistributionOrderReq(state.userOption);

    if (!body) return;

    const { subscription_id } = await this.$ecAxios.$post('/api/v1/distribution_course_subscriptions', {
      body,
      cancelToken,
    });
    if (subscription_id) commit('setSubscriptionId', subscription_id);
  },
  async giftOptions(this: Vue, { commit }: Ctx) {
    const result = await this.$ecAxios.$get('/api/v1/gift_options/wrappings', {});

    commit('setGiftOptionRes', result);
  },
  /* ユーザーオプションの値をリセット */
  resetUserOption(this: Vue, { commit }: Ctx) {
    commit('setUserOption', getDefaultUserOption());
    localStorage.removeItem(LOCAL_STORAGE_KEY.USER_OPTION);
  },
  async uploadPdfFile(
    this: Vue,
    context: Ctx,
    { fileData, cartId, cancelToken }: BaseAxiosAction<{ fileData: any; cartId: number }>
  ): Promise<string> {
    const { file_id } = await this.$ecAxios.$put(
      '/api/v1/carts/{id}/upload',
      {
        path: {
          id: cartId,
        },
        cancelToken,
        // @ts-expect-error
        body: fileData,
      },
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );

    return file_id;
  },
  async postAmazonOrder(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/user_amazon_pay/orders', 'post'>) {
    return await this.$ecAxios.$post('/api/v1/user_amazon_pay/orders', payload);
  },
  async postGuestAmazonOrder(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/guest_amazon_pay/orders', 'post'>
  ) {
    return await this.$ecAxios.$post('/api/v1/guest_amazon_pay/orders', payload);
  },
  async postDistributionAmazonOrder(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/amazon_pay/distribution_course_subscriptions', 'post'>
  ) {
    return await this.$ecAxios.$post('/api/v1/amazon_pay/distribution_course_subscriptions', payload);
  },
  /** 楽天Pay charge_id取得 */
  async postRakutenPayOrder(this: Vue, context: Ctx, payload: ECActionPayload<'/api/v1/rakuten_pay/orders', 'post'>) {
    return await this.$ecAxios.$post('/api/v1/rakuten_pay/orders', payload);
  },
  /**　Veritrans 決済確定情報を取得 */
  async fetchVeritransPaymentResult(
    this: Vue,
    { commit }: Ctx,
    payload: ECActionPayload<'/api/v1/veritrans/payments/{transaction_id}/result', 'post'>
  ) {
    const { success, order } = await this.$ecAxios.$post('/api/v1/veritrans/payments/{transaction_id}/result', payload);

    if (order) {
      commit('setVeritransOrderInfo', order);
    }

    return { success, order };
  },
  /** Paidy payload取得 */
  async fetchPaidyPayload(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/orders/{id}/paidy_payload', 'get'>
  ) {
    return await this.$ecAxios.$get('/api/v1/orders/{id}/paidy_payload', payload);
  },
  /** Paidy 購入完了リクエスト */
  async postPaidyAuthorize(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/paidy/authorize_success', 'post'>
  ) {
    return await this.$ecAxios.$post('/api/v1/paidy/authorize_success', payload);
  },
  /** Paidy 仮注文キャンセル */
  async postPaidyOrderCancel(
    this: Vue,
    context: Ctx,
    payload: ECActionPayload<'/api/v1/orders/{id}/cancel_paidy_tentative_order', 'post'>
  ) {
    await this.$ecAxios.$post('/api/v1/orders/{id}/cancel_paidy_tentative_order', payload);
  },
  /** 頒布会情報を取得（外部サービス送信用） */
  async fetchDistributionInfo(this: Vue, { commit }: Ctx, payload: BaseAxiosAction<{ distributionCourseId: number }>) {
    const {
      response: { docs },
    } = await this.$searchAxios.$get('/select', {
      query: {
        wt: 'json',
        q: `mst:product AND iid:distribution_${payload.distributionCourseId}`,
        fl: [
          'product_code_s',
          'product_name_s',
          'lowest_price_general_i',
          'main_image_url_s',
          'product_type_s',
          'display_flag_i',
        ],
      },
    });
    commit('setDistributionCourseInfo', {
      product_code_s: docs[0].product_code_s,
      product_name_s: docs[0].product_name_s,
      lowest_price_general_i: docs[0].lowest_price_general_i,
      main_image_url_s: docs[0].main_image_url_s,
      product_type_s: docs[0].product_type_s,
      display_flag_i: docs[0].display_flag_i,
    });
  },
};

export type Store = DefineStoreModule<'modules/order', State, typeof getters, typeof mutations, typeof actions>;
